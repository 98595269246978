<template>
  <div class="compon_echarts">
    <div class="compon_echarts_enterprise" ref="echarts"></div>
  </div>
</template>

<script>
// 引入echarts
import * as echarts from 'echarts'

export default {
  name: 'ComponEcharts',
  computed: {
    roleId() {
      return JSON.parse(sessionStorage.getItem('roleId'))
    },
    unitId() {
      return sessionStorage.getItem('unitId')
    }
  },
  props: {
    viewData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    initData(data) {
      this.renderEcharts(data)
    },
    renderEcharts(data) {
      const myChart = echarts.init(this.$refs.echarts)
      // 所有的数据
      /**
       * 根据角色id 判断
       * 当角色id= 1 或者 unitId = 54  展示贵阳各区县预算占比（万元）
       * 其余的展示 各客户经理拓展需求预算占比（万元）
       */
      // console.log(data);
      // console.log(flag);
      let flag = this.unitId.includes('54')

      let title =
        this.roleId == 4 || flag
          ? '贵阳各认领单位预算占比（万元）'
          : '各客户经理拓展需求预算占比（万元）'
      let roleType = this.roleId == 4 || flag ? '1' : '2'

      // 拿到数据的最大值  -- 向上取整
      let max = Math.ceil(Number(Math.max(...data.numValue.map((item) => item.value))))
      // 拿到数据的最小值  -- 向下取整
      let min = Math.floor(Number(Math.min(...data.numValue.map((item) => item.value))))
      // 计算分割间距
      let interval = 0
      if (max < 10) {
        max = 100
        min = 0
        interval = 50
      } else if (min == max) {
        interval = Math.ceil(max / 2)
      } else {
        interval = Math.ceil((max - min) / 2)
      }
      let option

      option = {
        // 可以显示整个图表

        dataZoom: [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: false,
            start: 0,
            bottom: '0%',
            end: 100
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 0,
            end: 100
          }
        ],
        title: {
          subtext: title, // 副标题文本
          left: 'left', // 标题水平居中
          top: 0,
          textStyle: {
            // 标题文本样式
          },
          subtextStyle: {
            // 副标题样式
            fontStyle: 'normal', // 字体风格
            fontWeight: 'bold', // 字体粗细
            fontSize: 12, // 字体大小，可以根据需要调整
            color: '#333333' // 字体颜色
          }
        },
        color: '#00BDFF',

        xAxis: {
          type: 'category',

          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: '#333333' //Y轴内容文字颜色
            },
            formatter: function (value) {
              const maxLength = 8 // 每行显示的最大字符数
              let result = ''
              for (let i = 0; i < value.length; i += maxLength) {
                result += value.substring(i, i + maxLength) + '\n'
              }
              return result
            },
            interval: 0 // 设置为0表示全部显示x轴坐标
          },
          // boundaryGap: false,
          nameTextStyle: {
            verticalAlign: 'bottom',

            backgroundColor: '#DCE9FE'
          },
          data: data.namevalue
        },
        yAxis: {
          type: 'value',
          // data: [0, 2, 4, 6, 16000, 20000, 24000],
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
              color: '#333333', //Y轴内容文字颜色
              fontSize: 10 // 设置y轴标题文字大小为25
            }
          },
          // y轴分割线
          splitLine: {
            show: true, // 显示分隔线
            lineStyle: {
              type: 'dashed', // 设置分隔线为虚线
              color: '#E4E4E4'
            }
          },
          min: min, // 最小值
          max: max, // 最大值
          interval: interval
        },
        series: [
          {
            data: data.numValue,
            type: 'bar',
            barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
            barMaxWidth: '50px', // 限制柱子的最大宽度
            color: '#5997ff',
            // 面积颜色
            itemStyle: {
              barBorderRadius: [4, 4, 0, 0] // 左上角和右上角为圆角，右下角和左下角为直角
            },
           
            label: {
              show: true, // 显示数值
              color: '#5997ff',
              position: 'top' // 在顶部显示
            },
            areaStyle: {
              color: '#5997ff'
            },

            symbolSize: 0 // 设置拐点小圆点大小
          }
        ]
      }

      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
      let that = this
      myChart.on(
        'mouseover',
        this.debounce(function (params) {
          that.$emit('Chartchange', params, true, roleType,'mouseover')
        }, 100)
      ) // 100ms 防抖
      myChart.on(
        'mouseout',
        this.debounce(function (params) {
          that.$emit('Chartchange', params, true, roleType,'mouseout')
        }, 100)
      ) // 100ms 防抖
    },
    debounce(func, wait) {
      let timeout
      return function (...args) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          func.apply(this, args)
        }, wait)
      }
    }
  }
}
</script>


<style scoped lang="less">
.compon_echarts {
  width: 100%;
  overflow: hidden;

  .compon_echarts_enterprise {
    height: 180px;
    overflow: hidden;
    width: 100%;
  }
}
</style>
