<template>
  <div
    class="customer_manager"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="top_chart">
      <div class="top_chart_left">
        <div class="top_chart_left_item">
          <div class="icon">
            <img class="icon_img" src="../../../assets/image/Group 1142815023.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">企业需求数（个）</span>
            <div class="numInfo">
              <span class="num">{{ requirementInfo.enterpriseDemandCount || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="top_chart_left_item">
          <div class="icon">
            <img class="icon_img" src="../../../assets/image/Group 1142815037.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">需求总数（个）</span>
            <div class="numInfo">
              <span class="num">{{ requirementInfo.totalDemandCount || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="top_chart_left_item">
          <div class="icon">
            <img class="icon_img" src="../../../assets/image/shabi.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">累计预算（万）</span>
            <div class="numInfo">
              <span class="num">{{ requirementInfo.cumulativeBudget || 0 }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="top_chart_right">
        <budgetChart ref="budgetChart" @Chartchange="Chartchange"></budgetChart>
      </div>
    </div>
    <div class="top_card">
      <el-form>
        <div class="form_flex">
          <el-form-item label="所在地区：">
            <city-select
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>

          <el-form-item label="走访日期：">
            <el-date-picker
              v-model="queryInfo.time"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="datePickerChange"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="需求预算：">
            <div class="radio_group">
              <el-radio-group v-model="queryInfo.value" @change="cashChange">
                <el-radio-button label="">全部</el-radio-button>

                <el-radio-button v-for="(item, index) in questList" :key="index" :label="item.id">{{
                  item.optionData
                }}</el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
        <div class="row" style="justify-content: space-between">
          <div class="row_flex">
            <el-button plain @click="exportDiagnoseGroup">批量导出</el-button>
            <div>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
            </div>
          </div>
          <div class="search_right">
            <el-input
              placeholder="请输入企业名称或者客户经理名称"
              v-model="queryInfo.keyword"
              class="input-with-select"
              clearable
              @clear="getRequirementListAPI"
            >
              <el-button slot="append" @click="getRequirementListAPI" type="primary"
                >搜索</el-button
              >
            </el-input>
          </div>
        </div>
      </el-form>
    </div>

    <div class="row">
      <div class="row_txt">
        <span>当前条件下共查出</span>
        <span class="color1">{{ total }}</span>
        <span>条需求记录</span>
      </div>
      <div class="sort" @click="handleSort()">
        <span>按新增时间</span>

        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="queryInfo.orderByParam === '1' ? 'top_active' : ''"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="queryInfo.orderByParam === '2' ? 'bottom_active' : ''"
          ></div>
        </div>
      </div>
    </div>

    <div class="card_list">
      <div class="card_item" v-for="(item, index) in list" :key="index">
        <div class="card_left">
          <div class="item_checkbox">
            <el-checkbox @change="latechange($event, item)" v-model="item.checked1" label="">
              <span class="txt_color">{{ item.companyFullName }}</span>
              <span class="center_margin">|</span>
              {{ item.district }}</el-checkbox
            >
          </div>
          <div>
            <div class="center_left">
              <span class="title_txt"> 需求描述：</span>
              <span class="context">
                {{ item.requirementDescribe }}
              </span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="card_right_left">
            <div class="card_right_left_item">
              <div class="top">
                <img src="../../../assets/image/yus.png" alt="" />
                <span>预算</span>
              </div>
              <div class="bottom_name">
                <span>{{ item.budget || '－' }}</span>
                <span v-if="item.budget">万</span>
              </div>
            </div>
            <div class="card_right_left_item">
              <div class="top">
                <span>附件</span>
              </div>

              <div
                class="bottom_name"
                v-if="item.requirementFile"
                @click="downloadImg(item.requirementFile)"
              >
                <!-- <a class="download-link" :href="item.requirementFile" target="_blank"> -->
                <span class="file-name">{{ getFileName(item.requirementFile) }}</span>
                <i class="el-icon-download"></i>
                <!-- </a> -->
              </div>

              <div class="bottom_name" v-else>-</div>
            </div>
          </div>
          <div class="btn_box">
            <div class="set_manager">
              <div class="middle_icon_box">
                <img v-if="item.customerHead" :src="item.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span class="manager_name">
                {{ item.customerName }}
              </span>
            </div>
            <div class="btn_list">
              <el-button type="text" class="size" @click="toVisitingDetails(item)">
                详情
              </el-button>
            </div>
            <div class="po_time">
              <span>走访时间：{{ item.visitTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :modal="false"
      :modal-append-to-body="false"
      title=""
      :visible.sync="dialogVisible"
      width="23%"
      :before-close="handleClose"
    >
      <div class="dialog_box" v-if="roleType == 2">
        <div class="dialog_name">
          <span class="nick_name">{{ dialogInfo.name }}</span>
          <span>拓展需求预算</span>
          <span class="num_price">{{ dialogInfo.data.value }}</span>
          <span class="dan_wei">万</span>
        </div>
        <div class="center_auto">
          <div class="item_flex" v-for="(item, index) in budgetList" :key="index">
            <div class="left_w">
              <span>{{ index + 1 }}、</span>
            </div>
            <div class="center_w">
              <span>{{ item.companyFullName }}</span>
            
            </div>
            <div class="right_num">
              <span class="num">{{ item.budget||0 }}</span>
              <span class="dan_wei">万</span>
            </div>
          </div>
        </div>
        <div class="dialog_page" v-if="dialogTotal > 10">
          <el-pagination
            :current-page="dialogQuery.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="dialogQuery.pageSize"
            layout="  prev, pager, next"
            :total="dialogTotal"
            @size-change="handleSizeDialog"
            @current-change="handleCurrentDialog"
          />
        </div>
      </div>
      <div v-else class="dialog_box">
        <div class="dialog_name">
          <span class="nick_name">{{ dialogInfo.name }}</span>
          <span class="num_price">{{ dialogInfo.data.value }}</span>
          <span class="dan_wei">万</span>
        </div>
        <div class="center_two">
          <div class="item_flex" v-for="(item, index) in budgetList" :key="index">
            <div>
              <span>{{ index + 1 }}、</span>
            </div>
            <div>
              <span>{{ item.customerName }}</span>
            </div>
            <div class="right_num">
              <span class="num">{{ item.budget }}</span>
              <span class="dan_wei">万</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listPage,
  getBudgeResultToLeader,
  getBudgetPageResult,
  exportRequirement
} from '@/api/visiting.js'

// api
import { getRequirementChat, getRequirementList } from '@/api/demand.js'

import CitySelect from '@/components/CitySelect.vue'
import budgetChart from './components/budgetChart.vue'
const defaultQueryInfo = Object.freeze({
  customerId: 0, // 客户经理编号
  orders: 4, // 排序

  pageNum: 1, // 页数
  pageSize: 10, // 每页数据条数
  city: '贵阳市', // 市
  companyName: '', // 企业名称
  customerName: '', // 客户经理名称
  district: '', // 区/县
  startTime: '', // 走访开始时间
  endTime: '', // 走访结束时间
  keyword: '', // 搜索关键字
  orderByParam: '0', // 排序字段 升序 1 降序 2
  timeType: '0', // 时间类型：0代表日、1代表周、2代表月
  type: '', // 企业榜单类型：0代表走访次数、1代表走访需求、2代表推送案例、3代表潜在线索

  minPrice: 0, // 最低价格
  maxPrice: 0, // 最高价格

  time: '', // 日期
  optionIdList: [],
  value: ''
})

export default {
  name: 'Visiting',
  // uploadLogo
  components: { CitySelect, budgetChart },
  data() {
    return {
      roleType: '2',
      dialogVisible: false,
      loading: false,
      dialogInfo: {
        data: {}
      },
      dialogTotal: 0,
      budgetList: [],
      dialogQuery: {
        customerId: '',
        pageNum: 1, // 页数
        pageSize: 10 // 每页数据条数
      },
      questList: [
        {
          id: 1,
          optionData: '0-200万',
          minPrice: 0,
          maxPrice: 200
        },
        {
          id: 2,
          optionData: '200-500万',
          minPrice: 200,
          maxPrice: 500
        },
        {
          id: 3,
          optionData: '500-1000万',
          minPrice: 500,
          maxPrice: 1000
        },
        {
          id: 4,
          optionData: '>1000万',
          minPrice: 1000,
          maxPrice: 0
        }
      ], //工具list
      isActive: '', // 排序图标样式
      customerId: null, // 选中的客户经理id
      queryInfo: { ...defaultQueryInfo }, // 企业查询一览
      list: [], // 企业列表
      total: 0, // 企业总条数
      isIndeterminate: false, //显示半选图标
      checkAll: false, //显示全选图标
      companyIdList: [], // 选中的企业id

      // 企业需求统计
      requirementInfo: {},
      // 企业需求列表params
      businessListParams: {}
    }
  },
  computed: {
    unitId() {
      return sessionStorage.getItem('unitId')
    }
  },
  created() {
    this.getRequirementListAPI()
  },
  mounted() {
    this.getRequirementChatAPI()
  },
  methods: {
    async downloadImg(url) {
      fetch(url).then((res) => {
        res.blob().then((blob) => {
          const a = document.createElement('a'), // 动态创建a标签
            downloadUrl = window.URL.createObjectURL(blob), // 创建blob对象
            name = url //指定下载文件名
          a.href = downloadUrl
          a.download = name
          a.click() //触发下载
          window.URL.revokeObjectURL(downloadUrl) //释放URL对象
        })
      })
    },
    Chartchange(val, flag, roleType, str) {
      if (str == 'mouseout') {
        this.dialogVisible = false
      } else {
        this.roleType = roleType
        this.dialogQuery.customerId = val.data.id
        if (roleType == 2) {
          this.getBudgetPageResult()
        } else {
          this.getBudgeResultToLeader(val.data.unitId)
        }

        this.dialogVisible = flag
        this.dialogInfo = val
      }
    },
    async getBudgeResultToLeader(id) {
      const res = await getBudgeResultToLeader({ unitId: id })
      if (res.resultCode == 200) {
        this.budgetList = res.data
      }
    },
    //弹窗
    async getBudgetPageResult() {
      const res = await getBudgetPageResult(this.dialogQuery)
      if (res.resultCode == 200) {
        this.budgetList = res.data.list
        
        this.dialogTotal = res.data.total
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 企业需求统计
    getRequirementChatAPI() {
      getRequirementChat().then((res) => {
        this.requirementInfo = res.data || {}

        if (res.data && Array.isArray(res.data.budgetDistributionVoList)) {
          const namevalue = res.data.budgetDistributionVoList.map((item) => {
            return item.name
          })
          let list = res.data.budgetDistributionVoList

          const numValue = []
          list.forEach((el) => {
            numValue.push({
              id: el.id,
              unitId: el.unitId,
              value: el.budget
            })
          })

          this.$nextTick(() => {
            this.$refs.budgetChart?.initData({ namevalue, numValue })
          })
        }
      })
    },
    // 获取列表
    getRequirementListAPI() {
      this.loading = true

      getRequirementList(this.queryInfo)
        .then((res) => {
          this.list = res.data.list
          this.total = res.data.total
        })
        .finally((fin) => {
          this.loading = false
        })
    },
    //全选框
    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.list.forEach((item) => {
          //orgCompanyId
          this.companyIdList.push(item.id)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.list.forEach((item) => {
          this.companyIdList = []
          this.checkAll = false
          this.$set(item, 'checked1', false)
        })
      }

      this.isIndeterminate = false
    },
    // 导出诊断记录
    async exportDiagnoseGroup() {
      // if(!this.companyIdList.length){
      //   this.$message.warning('请先选择企业!')
      //   return
      // }
      this.loading = true
      let ids = []
      if (this.checkAll) {
        ids = []
      } else {
        ids = this.companyIdList
      }

      const query = { ...this.queryInfo, ids }
      delete query.pageNum
      delete query.pageSize
      const res = await exportRequirement(query)
      if (res) {
        this.loading = false
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date

        a.download = `${nowtime} 需求记录.xlsx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.loading = false
        this.$message.warning('下载失败!')
      }
    },
    // 多选框选中数据
    latechange(bol, row) {
      // orgCompanyId
      const index = this.companyIdList.indexOf(row.id)
      if (index == -1) {
        //orgCompanyId
        this.companyIdList.push(row.id)
      } else {
        this.companyIdList.splice(index, 1)
      }
      if (bol) {
        if (this.companyIdList.length === this.list.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.companyIdList.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },

    //排序
    handleSort() {
      if (this.queryInfo.orderByParam === '0') {
        this.queryInfo.orderByParam = '1'
      } else if (this.queryInfo.orderByParam === '1') {
        this.queryInfo.orderByParam = '2'
      } else {
        this.queryInfo.orderByParam = '0'
      }
      this.getRequirementListAPI()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getRequirementListAPI()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.getRequirementListAPI()
    },
    //监听pagesize变化
    handleSizeDialog(newSize) {
      this.dialogQuery.pageSize = newSize
      this.getBudgetPageResult()
    },
    //监听页码变化
    handleCurrentDialog(newCurrent) {
      this.dialogQuery.pageNum = newCurrent
      this.getBudgetPageResult()
    },

    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.getRequirementListAPI()
    },
    // 时间段
    datePickerChange(e) {
      if (Array.isArray(e) && e.length === 2) {
        this.queryInfo.startTime = e[0]
        this.queryInfo.endTime = e[1]
        this.getRequirementListAPI()
      }
    },
    // 需求预算
    cashChange(e) {
      if (e) {
        const index = this.questList.findIndex((item) => item.id === e)

        if (index !== -1) {
          this.queryInfo.minPrice = this.questList[index].minPrice
          this.queryInfo.maxPrice = this.questList[index].maxPrice
        }
      } else {
        this.queryInfo.minPrice = 0
        this.queryInfo.maxPrice = 0
      }

      this.getRequirementListAPI()
    },
    //去详情页
    toVisitingDetails(item) {
      this.$router.push({
        path: '/service/demanddetails',
        query: {
          id: item.id
        }
      })
    },
    // 文件名
    getFileName(url) {
      if (typeof url === 'string') {
        return url.substring(url.lastIndexOf('/') + 1)
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: auto;
  margin: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #909399;
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0px 30px;
  color: #606266;
  font-size: 0.875rem;
  word-break: break-all;
}
::v-deep .el-dialog {
  position: relative;
  margin: 0 100px 0 1000px;
  background: #fff;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  pointer-events: auto;
  top: 100px;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #333333;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 6px 10px;
  font-size: 14px;
  border-radius: 0;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  margin: 1px;
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #8fb9ff;
  border-radius: 4px;
}
::v-deep .el-form-item {
  margin-bottom: 14px;
}
.customer_manager {
  height: 100vh;
}
::v-deep .el-table__header-wrapper .el-checkbox {
  display: none;
}
.el-form-item ::v-deep .el-form-item__label {
  color: #333333;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
}
.distpicker-address-wrapper ::v-deep select {
  width: 150px;
}
.distpicker-address-wrapper ::v-deep label {
  margin-right: 10px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}

::v-deep .el-button--info {
  color: rgb(0, 0, 0);
  background-color: #f4f4f4;
  border-color: #c9c9c9;
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
  position: relative;
  top: -16px;
}
.search_right {
  width: 460px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}

.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    margin: 0px 20px;
    font-size: 16px;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .row_txt {
    font-size: 16px;
  }
  .row_flex {
    display: flex;
    align-items: center;
    margin-left: 18px;
    div {
      margin: 0px 20px;
    }
  }
}

.sort {
  display: flex;
  align-items: center;
  margin-left: 20px;
  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    margin-right: 5px;
  }
  .sort_icon {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: auto;
    i {
      width: 14px;
      height: 14px;
      color: #dbd6d6;
      font-size: 14px;
    }
    .isActive {
      color: #8a8888 !important;
    }
  }
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 10px 0px 10px;
  box-sizing: border-box;
  .form_flex {
    display: flex;
    justify-content: space-between;
    padding: 0px 100px 0px 20px;
  }
}
.top_chart {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 10px 10px 40px;
  height: 150px;
  box-sizing: border-box;
  .top_chart_right {
    flex: 1;
    height: 150px;
  }
  .top_chart_left {
    display: flex;
    align-items: center;
    .top_chart_left_item {
      display: flex;
      margin-right: 40px;
      .icon {
        .icon_img {
          width: 52px;
          height: 52px;
        }
      }
      .data_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        .title {
          font-size: 16px;
        }
        .numInfo {
          font-size: 24px;
        }
      }
    }
  }
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: calc(100vh - 380px);

  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .po_reportingStatus {
    position: absolute;
    color: #f53939;
    top: 50%;
    transform: translateY(-50%);
    left: 28%;
    z-index: 999 !important;
    img {
      width: 76px;
      height: 60px;
    }
  }

  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 130px;
    background-color: #fff;
    margin-bottom: 16px;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    position: relative;
    border-left: 7px solid #4482ff;
    border-radius: 4px;
    .card_top {
      position: absolute;
      top: 10px;
      left: 6px;
      height: 30px;
      min-width: 84px;
      color: #ffffff;
      z-index: 99999;
      text-align: center;
      line-height: 30px;
      border-radius: 15px 20px 20px 0;
    }
    .card_left {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin-left: 20px;
      .item_checkbox {
        .txt_color {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .center_margin {
          margin: 0px 5px;
          color: #c4c4c4;
        }
      }
      .center_left {
        margin-top: 10px;
        height: 60px;
        min-width: 750px;
        max-width: 750px;
        background: #f6fbff;
        padding: 10px;
        .title_txt {
          font-style: italic; /* 设置为斜体 */
          color: #333333;
          font-size: 14px;
          font-weight: bold;
        }
        .context {
          font-size: 14px;
          color: #525151;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .card_right_left {
        display: flex;
        .card_right_left_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          margin-left: 70px;
          font-size: 18px;
          width: 100px;

          cursor: pointer;
          .top {
            display: flex;
            margin-bottom: 20px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 5px;
            }
          }
          .bottom_name {
            color: #4e93fb;
            white-space: nowrap;
            font-size: 20px;
            display: flex;
            align-items: center;
            .download-link {
              display: flex;
              align-items: center;
              color: #4e93fb;
            }

            .file-name {
              display: inline-block;
              width: 5rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;

        .po_time {
          position: absolute;
          bottom: 10px;
          right: 30px;
          color: #999999;
          font-size: 14px;
        }
        .divs {
          margin-right: 10px;
        }
        .set_manager {
          display: flex;
          align-items: center;
          min-width: 120px;
          margin-right: 0px;
          .middle_icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #f4f4f4;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 35px;
              height: 35px;
            }
            i {
              font-size: 16px;
              color: #c9c9c9;
            }
          }
          .manager_name,
          .wait_set {
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
}
.color_green {
  background-color: #0ecbc6;
}
.color_blue {
  background-color: rgb(68, 138, 255);
}
.color_purple {
  background-color: #6a70ff;
}
.color_gray {
  background-color: #a1a1a1;
}
.type_tag {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.type_tag1 {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.row_tag {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
}
.btn_color {
  color: #448aff;
  border-color: #448aff;
}
.dialog_box {
  margin-top: 20px;
  padding-bottom: 20px;
  .center_auto {
    min-height: 100px;
    margin-left: 30px;
    .item_flex {
    
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 10px 0px;
    .left_w{
      min-width: 30px;
    }
    .center_w{
      width: 60%;
    }
  }
  }
  .dialog_page {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  .dialog_name {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 16px;
    position: relative;
    top: -10px;
    .nick_name {
      font-weight: bold;
    }
    .num_price {
      color: #448aff;
      font-weight: bold;
      margin-left: 20px;
    }
  }
  .dan_wei {
    font-size: 12px;
  }

  .right_num {
    margin-left: 20px;
    .num {
      color: #448aff;
      font-weight: bold;
    }
  }
  .center_two {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
    .item_flex {
      margin-left: 10px;
      width: 40%;
      margin: 10px 0px;
      display: flex;
      justify-content: left;
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog__header {
  padding: 0;
  padding-bottom: 0.625rem;
}
</style>
